import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import shevronUp from "../../../style/icons/ShevronUp/shevron-up.svg";
import { connect } from "react-redux";
import { getThePatient } from "../../../redux/patients/actions";
import ChangeLoginModal from "../../components/ChangeLoginModal";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import "./RelativesList.scss";

const RelativesList = ({
                         relatives = [],
                         id,
                         setShowRelatives,
                         getThePatient,
                         replaceAva,
                         setDetails,
                         setHistoryItem,
                         history,
                         toUpperCase,
                         userPhone,
                       }) => {
  const { t } = useTranslation();


  const userId = history.location.search
    .replace("?", "")
    .split("&")
    .filter((_) => _.includes("user"))
    .join("")
    .replace("user=", "")
    .match(/(\d+)/)[0]; //params.patientId;
  const changeCard = (person) => {
    const currentPathNameArray = window.location.pathname.split("/");

    setDetails(false);
    if (id) {
      getThePatient(id, person.id);
    } else {
      getThePatient(currentPathNameArray[4], person.id);
    }
    // "person.patientId/id/?user=userId"
    // history.push(
    //   `/home/${role}/client/${person.patientId}/${person.id}/?user=${patientID}`
    // );

    history.push(
      `/${currentPathNameArray[1]}/${currentPathNameArray[2]}/${currentPathNameArray[3]}/${person.patientId}/${person.id}/?user=${userId}&consultId=`,
    );
    setShowRelatives(false);
  };


  if (!relatives) return null;

  return (
    <div
      className={"showRelativesWrapper"}
      onClick={() => {
        setShowRelatives(false);
      }}
    >
      <div className="showRelatives">
        {relatives.reduce((prev, person) => {
          let changePerson;
          if (!prev.changePerson) {
            if (person.relationShip.title === "me" || person.relationShip.title === "я") {
              prev.changePerson = true;
              changePerson = (
                <>
                  <ChangeLoginModal userPhone={userPhone} userId={userId} history={history} person={person} />
                </>
              );
            } else {
              if (relatives.length >= 1) {
                prev.changePerson = true;
                changePerson = (
                  <>
                    <ChangeLoginModal userPhone={userPhone} userId={userId} history={history} person={person} />
                  </>
                );
              }
            }
          }

          prev.relatives.push((
            <div
              key={person.id}
              className={"showRelatives-unit"}
              onClick={() => {
                setHistoryItem(undefined);
                setDetails(false);
                changeCard(person);
              }}
            >
              {person && person.photoUrl && (
                <img
                  src={person.photoUrl}
                  className={"showRelatives-unit-photo"}
                />
              )}
              {person && person.photoUrl === "" && (
                <div className={"showRelatives-unit-replaceAva"}>
                  {replaceAva(person)}
                </div>
              )}
              <div className={"showRelatives-unit-data"}>
                <div className="showRelatives-unit-data-name">
                  {person.fullName}
                </div>
                <div className="showRelatives-unit--data-phone">{`${t("phone")} ${
                  person.phone
                }`}</div>
              </div>
              <div className="showRelatives-unit-relation">
                ({person.relationShip && toUpperCase(person)})
                {changePerson ?? null}
              </div>
            </div>
          ));
          return prev;
        }, {
          relatives: [],
          changePerson: null,
        })?.relatives || []}
        {relatives && (
          <img
            src={shevronUp}
            alt="shevronUp"
            className={"shevronUp"}
            onClick={() => {
              setShowRelatives(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = ({ patients }) => ({
  id:
    patients &&
    patients.patient &&
    patients.patient.patient &&
    patients.patient.patient.id,
  relatives: patients && patients.cards && patients.cards.items,
});
const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    getThePatient,
  }),
);
export default enhance(RelativesList);